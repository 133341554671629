<template>
    <validation-observer rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="isOpen"
      :loading="isLoading"
      @submit="handleSubmit(onSubmit)"
      submit="Submit"
      @close="onClose"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple">
          Re-Engage Employee
        </p>
      </template>
      <template v-slot:subtitle>
        <div class="flex flex-col justify-start pb-5">
          <p class="font-semibold text-sm text-darkPurple mb-2">Employee Details</p>
          <div class="flex justify-start items-start gap-5">
            <div class="w-44 h-32 rounded-md text-blueCrayola font-normal">
                <img class="w-44 h-32 rounded-md" v-if="employeeInfo.photo" :src="employeeInfo.photo" />
                <div v-else class="h-32 border rounded-md flex justify-center items-center">
                <span style="font-size:60px">
                  {{ $getInitials(`${employeeInfo.fname || ''} ${employeeInfo.lname || ''}`) }}
                </span>
                </div>
              </div>
            <div class="flex flex-col justify-start items-start gap-2 w-full">
              <div class="w-full flex flex-col justify-start items-start gap-2">
                <h4 class="font-bold text-lg leading-5 text-darkPurple">
                  {{ employeeInfo.fname }} {{ employeeInfo.lname }}
                </h4>
                <div class="flex flex-col justify-start items-center w-full gap-0">
                  <div class="flex justify-start items-start w-full">
                    <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                      Function:
                    </p>
                    <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
                    <span v-if="employeeInfo.orgFunction">
                      {{ employeeInfo.orgFunction.name }}
                    </span>
                    <span v-else>-</span>
                    </p>
                  </div>
                  <div class="flex justify-start items-start w-full">
                    <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                      Designation:
                    </p>
                    <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
                    <span v-if="employeeInfo.userDesignation">
                        {{ employeeInfo.userDesignation.name }}
                    </span>
                    <span v-else>-</span>
                    </p>
                  </div>
                  <div class="flex justify-start items-start w-full">
                    <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                      Job Level:
                    </p>
                    <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
                      <span v-if="employeeInfo.userLevel">
                        {{ employeeInfo.userLevel.name }}
                      </span>
                      <span v-else>-</span>
                    </p>
                  </div>
                  <div class="flex justify-start items-start w-full">
                    <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                      Office Location:
                    </p>
                    <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
                    <span v-if="employeeInfo.employeeOffice">
                        {{ employeeInfo.employeeOffice.name }}
                    </span>
                    <span v-else>-</span>
                    </p>
                  </div>
                  <div class="flex justify-start items-start w-full">
                    <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                      Reporting to
                    </p>
                    <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
                    <span v-if="employeeInfo.userReportingTo">
                        {{ employeeInfo.userReportingTo.fname }} {{ employeeInfo.userReportingTo.lname }}
                    </span>
                    <span v-else>-</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:default>
        <div class="flex flex-col justify-start divide-y divide-dashed divide-romanSilver">
          <div class="flex-flex-col justify-start gap-5 pb-5">
            <h3 class="font-bold text-lg text-darkPurple leading-5">
              Re-engagement Details
            </h3>
            <div class="flex flex-col justify-start gap-1 mt-2">
              <div class="flex flex-row justify-start items-center">
                <p class="w-1/2 font-bold _text-10 uppercase text-romanSilver leading-5">
                  proposed date:
                </p>
                <p class="w-1/2 font-semibold text-sm text-jet leading-5">
                  {{ $DATEFORMAT(new Date(payload.effectiveDate), "MMMM dd, yyyy") }}
                </p>
              </div>
            </div>
          </div>
          <div class="flex flex-col justify-start gap-5 pt-4">
            <div class="text-sm text-jet leading-5">
              <combobox
                placeholder="--Type or Select--"
                label="Reason for Re-Engagement"
                :items="reasonsOptions"
                v-model="payload.reason"
                variant="w-full"
              />
            </div>
            <div class="">
              <label name="comment" class="date-label">
                Comment
              </label>
              <c-textarea
                placeholder="--Select--"
                variant="w-full"
                :col="50"
                type="textarea"
                name="comment"
                v-model="payload.comment"
              />
            </div>
            <div>
              <template>
                <div class="cursor-pointer flex justify-end" @click="isOpenAttachment = true">
                  <div class="flex justify-center items-center gap-2 px-3 py-2 hover:bg-ghostWhite rounded-md">
                    <Icon icon-name="icon-plus" size="xs" class="text-flame" />
                    <p class="font-semibold text-base leading-5 text-flame">Upload Attachment</p>
                  </div>
                </div>
              </template>
              <template v-if="isOpenAttachment">
                <div class="overlayer flex flex-wrap justify-center items-center">
                  <Card class="bg-white relative p-4 flex flex-col justify-end items-end gap-2">
                    <div @click="isOpenAttachment = false">
                      <Icon
                        icon-name="close"
                        size="xs"
                        class="text-darkPurple cursor-pointer"
                      />
                    </div>
                    <file-upload />
                  </Card>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </RightSideBar>
    </validation-observer>
</template>

<script>
import * as _ from 'lodash'
import { format } from 'date-fns'
import { ValidationObserver } from "vee-validate"
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Combobox from "@scelloo/cloudenly-ui/src/components/combobox";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import FileUpload from '@scelloo/cloudenly-ui/src/components/file-upload';
import RightSideBar from "@/components/RightSideBar";

export default {
  components: {
    ValidationObserver,
    FileUpload,
    RightSideBar,
    Card,
    CTextarea,
    Combobox
  },
  data() {
    return {
      isLoading: true,
      isOpen: false,
      isOpenAttachment: false,
      reasonsOptions: [],
      payload: {
        userId: '',
        reason: null,
        comment: '',
        effectiveDate: '',
        confirmDisengageId: '',
      },
    }
  },
  methods: {
    toggle(payload){
      this.payload = {
        ...this.payload,
        userId: payload.disengaged.userId,
        effectiveDate: payload.disengaged.effectiveDate,
        confirmDisengageId: payload.disengageStat.id
      }
      this.employeeInfo = payload
      this.isOpen = true
    },
    onClose(){
      this.isOpen = false
      this.isLoading = false
      _.assign(this.$data.payload, this.$options.data().payload)
    },
    async onSubmit(){
      this.isLoading = true
      const findReasonIndex = _.find(this.reasonsOptions,
        reason => reason.id === this.payload.reason
      )
      if(!_.size(_.keys(findReasonIndex))){
        await this.$_suspensionReason({orgId: this.$orgId, reason: this.payload.reason})
        .then(({ data }) => {this.payload.reason = data.reason.id})
        .catch((error) => this.$toasted.error(error.message, {duration: 3000}))
      }

      const payload = {
        orgId: this.$orgId,
        userId: this.payload.userId,
        reasonId: this.payload.reason,
        comments: this.payload.comment,
        effectiveDate: format(new Date(), "yyyy-MM-dd"),
        confirmDisengageId: this.payload.confirmDisengageId,
      }

      await this.$_reEngageOrgEmployee(payload).then(({ data }) => {
        this.$toasted.success(data.message, {duration: 3000})
        this.$emit('success', data)
        this.onClose()
      }).catch((error) => {
        this.$toasted.error(error.message, {duration: 3000})
        this.isLoading = false
      })

    },
    async getSuspensionReason(){
      await this.$_getSuspensionReason().then(({ data }) => {
        this.reasonsOptions = data.reasons?.map(reason => ({
          ...reason, value: reason.reason
        }))
      })
    },
  },
  watch:{
    async isOpen(value){
      if(value){
        this.isLoading = true
        await this.getSuspensionReason()
        this.isLoading = false

      }
    }
  },
};
</script>

<style scoped>
._font-10 {
  font-size: 10px !important;
}
._text-10 {
  font-size: 10px !important;
}
</style>
<style>
.rightSidebar {
  width: 520px !important;
}
</style>
